import React from 'react';

const Answers = ({ question, answer }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-2xl font-bold mb-4">Answers</h2>
      {question ? (
        <>
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Question:</h3>
            <p className="text-gray-700">{question}</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Answer:</h3>
            <p className="text-gray-700">{answer}</p>
          </div>
        </>
      ) : (
        <p className="text-gray-500">Select a question from the history or ask a new question to see the answer here.</p>
      )}
    </div>
  );
};

export default Answers;