import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import SignUpModal from '../UserComponents/SignUpModal';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
      if (!user) {
        setShowModal(true);
      }
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner
  }

  if (!isAuthenticated) {
    return (
      <>
        <SignUpModal 
          isOpen={showModal} 
          onClose={() => {
            setShowModal(false);
            navigate('/');
          }}
          onSuccess={() => setIsAuthenticated(true)}
        />
        {!showModal && <Navigate to="/" replace />}
      </>
    );
  }

  return children;
};


export default ProtectedRoute;