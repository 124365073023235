import React from 'react';

const History = ({ savedSearches, onQuestionClick }) => {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Search History</h2>
      {savedSearches.length > 0 ? (
        <ul className="divide-y divide-gray-300">
          {savedSearches.map((search, index) => (
            <li 
            key={search.id} 
             className="mb-2 pb-2 border-b border-gray-300 last:border-b-0 cursor-pointer hover:bg-gray-200 p-2 rounded transition duration-150 ease-in-out"
            onClick={() => onQuestionClick(search.question, search.answer)}
            >
              <p className="text-sm font-medium">{search.question}</p>
              <span className="text-xs text-gray-500">
              {new Date(search.timestamp.seconds * 1000).toLocaleString()}
              </span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-sm text-gray-500">No search history yet.</p>
      )}
    </div>
  );
};

export default History;