import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBOt7LM9ZcjR_8LkjxONkTQqG1pNQRYvco",
    authDomain: "budget-d155c.firebaseapp.com",
    projectId: "youtube-budget",
    storageBucket: "youtube-budget.appspot.com",
    messagingSenderId: "919876122899",
    appId: "1:919876122899:web:25d1280953c5a8fab742e6"
  };

  console.log('Initializing Firebase...');
  let app;
  try {
    app = initializeApp(firebaseConfig);
    console.log('Firebase app initialized successfully');
  } catch (error) {
    console.error('Error initializing Firebase app:', error);
    throw error;
  }
  
  console.log('Getting Auth instance...');
  let auth;
  try {
    auth = getAuth(app);
    console.log('Auth instance created successfully');
  } catch (error) {
    console.error('Error creating Auth instance:', error);
    throw error;
  }
  
  console.log('Firebase and Auth initialized successfully');
  
  export { auth };
  export const firestore = getFirestore(app)