import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import { collection, addDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { History as HistoryIcon } from 'lucide-react';
import SearchComponent from '../AppComponents/Search';
import History from '../AppComponents/History';
import Answers from '../AppComponents/Answers';

const AppHome = () => {
  const [savedSearches, setSavedSearches] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState('');
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showHistory, setShowHistory] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSearchHistory = async () => {
      const user = auth.currentUser;
      if (user) {
        const searchesRef = collection(firestore, 'searches');
        const q = query(
          searchesRef,
          where('userId', '==', user.uid),
          orderBy('timestamp', 'desc'),
          limit(50)
        );
        const querySnapshot = await getDocs(q);
        const searches = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSavedSearches(searches);
      }
    };

    fetchSearchHistory();
  }, []);

  const handleSearchComplete = async (question, answer) => {
    const user = auth.currentUser;
    if (user) {
      const newSearch = {
        userId: user.uid,
        question,
        answer,
        timestamp: new Date()
      };

      // Add to Firestore
      try {
        const docRef = await addDoc(collection(firestore, 'searches'), newSearch);
        
        // Update local state
        setSavedSearches(prevSearches => [{
          id: docRef.id,
          ...newSearch
        }, ...prevSearches].slice(0, 50));

        setSelectedQuestion(question);
        setSelectedAnswer(answer);
      } catch (error) {
        console.error('Error saving search:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  const handleQuestionClick = (question, answer) => {
    setSelectedQuestion(question);
    setSelectedAnswer(answer);
  };

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="relative min-h-screen">
       {/* Logo and Back to Landing Page Button */}
       <div className="absolute top-4 left-4 flex items-center">
       <Link to="/" className="flex items-center w-12 h-12 transition-transform duration-300 ease-in-out transform hover:scale-110">
        <img src="/logo.svg" alt="KidExplain Logo" className="w-12 h-12 transition-transform duration-300 ease-in-out transform hover:scale-110" />
        <span className="font-bold text-blue-600 ">KidExplain</span>
      </Link>
      </div>
      {/* Sign Out Button */}
      <div className="absolute top-4 right-4">
        <button onClick={handleSignOut} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          Sign Out
        </button>
      </div>
      <div className="container mx-auto px-4 py-8 pt-20">
        {/* Main Content */}
        <div className="mb-8">
          <h1 className="text-6xl font-bold mb-4 text-center">
          
          <span className="font-bold text-purple-600 ">
    Kid
  </span>
  <span className="text-blue-600 relative inline-block animated-underline group">Explain
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
        </span>
          </h1>
          <p className="text-xl mb-8 text-center">Let's start exploring!</p>
          
          <SearchComponent onSearchComplete={handleSearchComplete} />
        </div>

        {/* Flexible layout for History and Answers components */}
        <div className={`flex ${showHistory ? 'flex-col md:flex-row' : 'justify-center'} gap-8`}>
          {showHistory && (
            <div className="w-1/3 md:w-1/3 max-h-96 overflow-y-auto">
              <History savedSearches={savedSearches} onQuestionClick={handleQuestionClick} />
            </div>
          )}
          <div className={`w-full ${showHistory ? 'md:w-1/3' : 'max-w-2xl'}`}>
            <Answers question={selectedQuestion} answer={selectedAnswer}/>
          </div>
        </div>
      </div>

      {/* History Icon */}
      <div className="absolute bottom-4 left-4">
        <button 
          onClick={toggleHistory}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded-full"
          title={showHistory ? "Hide History" : "Show History"}
        >
          <HistoryIcon size={24} />
        </button>
      </div>

      <style jsx>{`
        .animated-underline::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: currentColor;
          transform: scaleX(0);
          transform-origin: bottom right;
          transition: transform 0.3s ease-out;
        }

        .group:hover .animated-underline::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      `}</style>
    </div>
  );
};



export default AppHome;