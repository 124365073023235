import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './MainComponents/Home';
import AppHome from './MainComponents/AppHome';
import ProtectedRoute from './MainComponents/ProtectedRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route 
          path="/app" 
          element={
            <ProtectedRoute>
              <AppHome />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </Router>
  );
};


export default App;
