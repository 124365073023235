import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {auth} from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Wand2, Lightbulb, Compass, Clock, MessageSquare, Cog, Smartphone } from 'lucide-react';




const FeatureCard = ({ icon, title, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md text-center">
    <div className="text-purple-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2 font-merriweather">{title}</h3>
    <p>{description}</p>
  </div>
);

const StepCard = ({ icon, title, description }) => (
  <div className="text-center mb-8 md:mb-0">
    <div className="text-purple-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2 font-merriweather">{title}</h3>
    <p>{description}</p>
  </div>
);

const TestimonialCard = ({ name, role, text }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <p className="mb-4">"{text}"</p>
    <p className="font-semibold">{name}</p>
    <p className="text-sm text-gray-600">{role}</p>
  </div>
);

const FAQItem = ({ question, answer }) => (
  <div>
    <h3 className="text-xl font-semibold mb-2 font-merriweather">{question}</h3>
    <p>{answer}</p>
  </div>
);

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      setShowModal(false);
      navigate('/app');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setShowModal(false);
      navigate('/app');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="bg-gradient-to-b from-purple-100 to-blue-200 min-h-screen font-poppins">
    {/* Header */}
    <header className="bg-white shadow-md">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div className="flex items-center">
          <img src="/logo.svg" alt="KidExplain Logo" className="w-10 h-10 mr-2" />
          <h1 className="text-3xl font-bold text-purple-600 font-merriweather">KidExplain</h1>
        </div>
        <button
          onClick={() => setShowModal(true)}
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition duration-300 text-xl"
        >
          Get Started
        </button>
      </div>
    </header>

    {/* Hero Section */}
    <section className="container mx-auto px-4 py-16 flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <h2 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6 font-merriweather">
          Explain the World to Your Little Ones with Ease
        </h2>
        <p className="text-xl text-gray-600 mb-8">
          Tired of being stumped by your child's endless questions? KidExplain is here to help you answer those curious inquiries in a way that's fun, simple, and perfectly tailored for young minds!
        </p>
        <button
          onClick={() => setShowModal(true)}
          className="bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-3 px-6 rounded-full text-xl transition duration-300 transform hover:scale-105 font-poppins"
        >
          Start Explaining Magic!
        </button>
      </div>
      <div className="md:w-1/2">
        <img src="/hero-img.jpg" alt="Happy parent and child" className="rounded-lg shadow-xl" />
      </div>
    </section>

    {/* Updated Hero Section */}
    <section className="bg-gradient-to-r from-blue-100 to-green-100 py-20">
        <div className="container mx-auto text-center">
          <h2 className="text-5xl md:text-6xl font-bold mb-6 font-nunito">
            Turn <span className="text-red-500">"I don't know"</span> into{' '}
            <span className="bg-yellow-300 px-2 py-1 rounded text-blue-700 inline-block transform -rotate-2 shadow-md">
            "Let's find out together!"
          </span>
          </h2>
          <p className="text-xl mb-8">KidExplain: Your AI-powered assistant for answering curious little minds</p>
          <button
           onClick={() => setShowModal(true)}
           className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg text-xl  transition duration-300 font-poppins">
            Start Explaining Magic
          </button>
        </div>
      </section>

      {/* Problem Statement Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-center font-nunito">Parenting is full of questions. We've got answers.</h2>
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <p className="text-lg mb-4">Ever been stumped by your little one's endless 'whys'? You're not alone! KidExplain is here to help you:</p>
              <ul className="list-disc list-inside">
                <li>Transform complex ideas into kid-friendly explanations</li>
                <li>Answer hundreds of daily questions without breaking a sweat</li>
                <li>Turn every question into a fun learning opportunity</li>
              </ul>
            </div>
            <div className="md:w-1/2 flex justify-center">
             <div className="w-full max-w-4xl mx-auto relative overflow-hidden rounded-lg shadow-lg">
      <div className="flex">
        {/* Left side: Frustrated parent */}
        <div className="w-1/2 relative">
          <img 
            src="/problem2-img.jpg" 
            alt="Frustrated parent surrounded by question marks" 
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-red-500 bg-opacity-30 flex items-center justify-center">
            <span className="text-6xl font-bold text-white">?</span>
          </div>
        </div>
        
        {/* Right side: Happy parent and child */}
        <div className="w-1/2 relative">
          <img 
            src="/solution-img.jpg" 
            alt="Happy parent and child high-fiving" 
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 right-0 bg-white p-2 rounded-tl-lg shadow">
            <span className="font-bold text-blue-600">KidExplain</span>
          </div>
        </div>
      </div>
      
      {/* Divider */}
      <div className="absolute top-0 bottom-0 left-1/2 w-1 bg-white transform -translate-x-1/2"></div>
    </div>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-yellow-50 py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center font-nunito">Your Parenting Superpowers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <FeatureCard icon={<Wand2 size={40} />} title="Kid-Friendly Translations" description="Turn grown-up knowledge into kid-speak with a tap" />
            <FeatureCard icon={<Lightbulb size={40} />} title="Endless Answers" description="Never run out of responses to curious 'whys' and 'hows'" />
            <FeatureCard icon={<Compass size={40} />} title="Learning Adventures" description="Transform every question into an exciting discovery journey" />
            <FeatureCard icon={<Clock size={40} />} title="Time-Saver" description="Quick, reliable answers when you need them most" />
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center font-nunito">As Easy as 1, 2, 3!</h2>
          <div className="flex flex-col md:flex-row justify-around items-center">
            <StepCard icon={<MessageSquare size={40} />} title="Ask" description="Type in your child's question or the topic you need help explaining" />
            <StepCard icon={<Cog size={40} />} title="Translate" description="Our AI magically transforms complex ideas into simple, fun explanations" />
            <StepCard icon={<Smartphone size={40} />} title="Explore" description="Share the kid-friendly answer and watch curiosity bloom!" />
          </div>
          <div className="text-center mt-12">
            <button 
            onClick={() => setShowModal(true)}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg text-xl font-merriweather">
              Get Started Now
            </button>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-purple-100 py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center font-nunito">Happy Parents, Curious Kids</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <TestimonialCard name="Sarah M." role="Mom of two" text="KidExplain has been a lifesaver! It's like having a patient, knowledgeable friend always ready to help." />
            <TestimonialCard name="John D." role="Dad of three" text="This app has made me look like a genius to my kids. I love watching their eyes light up with every explanation." />
            <TestimonialCard name="Emily R." role="Single parent" text="KidExplain gives me confidence to tackle any question my daughter throws at me. It's been a game-changer!" />
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold mb-12 text-center font-nunito">Got Questions? We've Got Answers!</h2>
          <div className="space-y-8">
            <FAQItem 
              question="Is KidExplain safe for my children to use?" 
              answer="Absolutely! While KidExplain is designed for parents to use, all explanations are child-friendly and age-appropriate." 
            />
            <FAQItem 
              question="How accurate are the explanations?" 
              answer="Our AI is trained on verified educational resources and is regularly updated. However, we always encourage parents to review and discuss the answers with their children." 
            />
            <FAQItem 
              question="Can I customize the explanations for my child's age?" 
              answer="Yes! You can set your child's age in the app, and KidExplain will tailor its explanations accordingly." 
            />
            <FAQItem 
              question="Is there a limit to how many questions I can ask?" 
              answer="Not at all! Our subscription plans offer unlimited questions, so you can nurture your child's curiosity without limits." 
            />
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="bg-gradient-to-r from-green-100 to-blue-100 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-4 font-merriweather">Ready to Become the Ultimate Question-Answering Superhero?</h2>
          <p className="text-xl mb-8">Join thousands of parents in nurturing curiosity, one question at a time!</p>
          <button 
          onClick={() => setShowModal(true)}
          className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-3 px-6 rounded-lg text-xl mb-4 font-merriweather">
            Start Your Free Trial
          </button>
          <p className="text-sm text-gray-600">No credit card required • Cancel anytime • Unlimited fun learning</p>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-blue-900 text-white py-8">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h2 className="text-2xl font-bold font-merriweather">KidExplain</h2>
            </div>
            <nav className="mb-4 md:mb-0">
              <ul className="flex space-x-4">
                <li><a href="/" className="hover:text-blue-300">Home</a></li>
                <li><a href="/" className="hover:text-blue-300">About</a></li>
                <li><a href="/" className="hover:text-blue-300">Features</a></li>
                <li><a href="/" className="hover:text-blue-300">Pricing</a></li>
                <li><a href="/" className="hover:text-blue-300">Contact</a></li>
              </ul>
            </nav>
            <div className="flex space-x-4">
              {/* Add social media icons here */}
            </div>
          </div>
          <div className="mt-8 text-center">
            <p>&copy; 2023 KidExplain. All rights reserved.</p>
          </div>
        </div>
      </footer>

      {/* Sign Up/Sign In Modal */}

    {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="flex mb-4">
              <button
                className={`flex-1 py-2 ${isSignUp ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setIsSignUp(true)}
              >
                Sign Up
              </button>
              <button
                className={`flex-1 py-2 ${!isSignUp ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                onClick={() => setIsSignUp(false)}
              >
                Sign In
              </button>
            </div>
            <form onSubmit={handleEmailAuth}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 mb-4 border rounded"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 mb-4 border rounded"
              />
              <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white p-2 rounded mb-2">
                {isSignUp ? 'Sign Up' : 'Sign In'} with Email
              </button>
            </form>
            <button
              onClick={handleGoogleAuth}
              className="w-full bg-red-500 hover:bg-red-700 text-white p-2 rounded mb-4"
            >
              {isSignUp ? 'Sign Up' : 'Sign In'} with Google
            </button>
            {error && <p className="text-red-500 mt-2 mb-2">{error}</p>}
            <button
              onClick={() => setShowModal(false)}
              className="w-full bg-gray-300 hover:bg-gray-400 p-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

    </div>
  );
};




export default Home;