import React, { useState } from 'react';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

const SignUpModal = ({ isOpen, onClose, onSuccess }) => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      onSuccess();
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleAuth = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      onSuccess();
    } catch (error) {
      setError(error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative p-5 border w-96 shadow-lg rounded-md bg-white">
        <h2 className="text-2xl font-bold mb-4">
          {isSignUp ? 'Sign Up' : 'Sign In'} to Access
        </h2>
        <div className="flex mb-4">
          <button
            className={`flex-1 py-2 ${isSignUp ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setIsSignUp(true)}
          >
            Sign Up
          </button>
          <button
            className={`flex-1 py-2 ${!isSignUp ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setIsSignUp(false)}
          >
            Sign In
          </button>
        </div>
        <form onSubmit={handleEmailAuth}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
          />
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white p-2 rounded mb-2">
            {isSignUp ? 'Sign Up' : 'Sign In'} with Email
          </button>
        </form>
        <button
          onClick={handleGoogleAuth}
          className="w-full bg-red-500 hover:bg-red-700 text-white p-2 rounded mb-4"
        >
          {isSignUp ? 'Sign Up' : 'Sign In'} with Google
        </button>
        {error && <p className="text-red-500 mt-2 mb-2">{error}</p>}
        <button
          onClick={onClose}
          className="w-full bg-gray-300 hover:bg-gray-400 p-2 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SignUpModal;