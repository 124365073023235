import React, { useState } from 'react';
import { Search } from 'lucide-react';

const SearchComponent = ({onSearchComplete}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [savedSearches, setSavedSearches] = useState([]);
  const [aiResponse, setAiResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const callClaudeAPI = async (prompt) => {
    const API_URL = process.env.REACT_APP_API_URL || '/api/claude';

// Then use API_URL in your fetch call
const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.completion;
  };

  const handleSearch = async () => {
    if (searchTerm.trim() !== '') {
      setIsLoading(true);
      console.log('Searching for:', searchTerm);
      
      try {
       // const prompt = `Please respond to the following question in a warm, friendly tone that a 4-year-old can understand: "${searchTerm}" Use simple words and short sentences. Include examples related to things 4-year-olds are interested in, such as favorite toys, animals, cartoons, or simple daily activities.`;
       const prompt = `Please provide a warm, simple explanation that a parent can use to answer the following question from their 4-year-old child: "${searchTerm}" 

The explanation should:
1. Use language and concepts a 4-year-old can understand
2. Include 1-2 relatable examples or analogies using things familiar to 4-year-olds (like toys, animals, or daily activities)
3. Be brief (2-3 short sentences if possible)


Start the response with "Here's how you might explain this to your child:" and then provide the explanation.`;
       const response = await callClaudeAPI(prompt);
        setAiResponse(response);
        onSearchComplete(searchTerm, response)
       // onSearchSave(searchTerm)
        // Save the search term
       // setSavedSearches(prevSearches => [...prevSearches, searchTerm]);
      } catch (error) {
        console.error('Error fetching AI response:', error);
        setAiResponse('Sorry, there was an error processing your request.');
      } finally {
        setIsLoading(false);
      }
      
      // Clear the input after search
      setSearchTerm('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="flex flex-col items-center space-y-4 p-4">
      <div className="flex w-full max-w-sm items-center space-x-2">
        <input
          type="text"
          placeholder="Ask me anything..."
          value={searchTerm}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
        <button
          onClick={handleSearch}
          disabled={isLoading}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          {isLoading ? 'Thinking...' : (
            <>
              <Search className="h-4 w-4 mr-2" />
              Ask
            </>
          )}
        </button>
      </div>
     
     
    </div>
  );
};

export default SearchComponent;